<template>
  <div class="card col-xl-12 col-lg-12">
    <div class="card-header card-header-flex flex-column">
      <a-tabs
        class="kit-tabs"
        :default-active-key="activeKey"
        @change="callback"
      >
        <a-tab-pane :tab="$t('etablissement.anneeScolaire')" key="7" />

        <a-tab-pane :tab="$t('etablissement.etablissements')" key="1" />
        <a-tab-pane :tab="$t('etablissement.classes')" key="2" />
        <a-tab-pane :tab="$t('etablissement.salles')" key="3" />
        <a-tab-pane :tab="$t('etablissement.services')" key="4" />
        <a-tab-pane :tab="$t('etablissement.activites')" key="5" />
        <a-tab-pane :tab="$t('etablissement.typeQualification')" key="6" />
        <a-tab-pane :tab="$t('etablissement.other')" key="8" />
      </a-tabs>
    </div>
    <div class="card-body">
      <keep-alive>
        <component v-bind:is="currentTabComponent" :key="loadKey"></component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import activities from "./components/activities";
import buildings from "./components/buildings";
import classes from "./components/classes";
import rooms from "./components/rooms";
import qualifications from "./components/qualifications";
import services from "./components/services";
import schoolarYears from "./components/schoolarYears";
import autre from "./components/autre";

export default {
  name: "etablissement",
  components: {
    activities,
    buildings,
    qualifications,
    classes,
    services,
    schoolarYears,
    rooms,
    autre,
  },
  data() {
    return {
      activeKey: "7",
      loadKey: 0,
    };
  },
  computed: {
    currentTabComponent: function () {
      switch (this.activeKey) {
        case "1":
          return "buildings";
        case "2":
          return "classes";
        case "3":
          return "rooms";
        case "4":
          return "services";
        case "5":
          return "activities";
        case "6":
          return "qualifications";
        case "7":
          return "schoolarYears";
        case "8":
          return "autre";
        default:
          return "buildings";
      }
    },
  },
  methods: {
    callback: function (key) {
      this.activeKey = key;
      this.loadKey += 1;
    },
  },
};
</script>
