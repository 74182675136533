<template>
  <div class="card">
    <a-button
      type="dashed"
      size="large"
      icon="book"
      @click="showModal"
      style="margin-bottom: 20px"
    >
      {{ $t("action.ajouter") }}
    </a-button>
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :footer="false"
    >
      <a-form :form="form" @submit="addPosition">
        <a-form-item :label="$t('etablissement.nom')">
          <a-input
            :placeholder="$t('etablissement.nom')"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.nom'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <div class="form-actions mt-0">
          <a-button
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
          >
            {{ $t("action.ajouter") }}
          </a-button>

          <a-button key="back" @click="handleCancel" :disabled="loadingClasse">
            {{ $t("action.annuler") }}
          </a-button>
        </div>
      </a-form>
    </a-modal>
    <a-table
      :loading="tableLeading"
      :rowKey="'_id'"
      :columns="columns"
      :data-source="data"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
    >
      <template slot="name" slot-scope="text, record">
        <div key="name">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="(e) => handleChange(e.target.value, record._id, 'name')"
          />
          <template v-else> {{ text }} </template>
        </div>
      </template>
      <span slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
          <span v-if="record.editable">
            <a-button
              size="small"
              type="primary"
              @click="() => save(record._id)"
            >
              {{ $t("action.enregistrer") }}
            </a-button>
            <a-button size="small" type="danger">
              <a-popconfirm
                :title="$t('all.sureToCancelAlert')"
                @confirm="() => cancel(record._id)"
              >
                <a> {{ $t("action.annuler") }} </a>
              </a-popconfirm>
            </a-button>
          </span>
          <span v-else>
            <a-button
              type="primary"
              :disabled="editingKey !== ''"
              @click="() => edit(record._id)"
            >
              <a-icon type="edit" />{{ $t("action.modifier") }}
            </a-button>
          </span>
        </div>
      </span>
      <span slot="operationSupprimer" slot-scope="text, record">
        <div class="editable-row-operations">
          <span>
            <a-button :disabled="editingKey !== ''" type="danger">
              <a-popconfirm
                :title="$t('requis.supp')"
                @confirm="() => supp(record._id)"
              >
                <a>{{ $t("action.supprimer") }}</a>
              </a-popconfirm>
            </a-button>
          </span>
        </div>
      </span>
    </a-table>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
var classesData = [];
export default {
  name: "qualifications",
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "DepenseForm" });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Type Qualifications",
    });

    await apiClient
      .post("/positions/filter", {
        query: { status: "active" },
      })
      .then((res) => {
        classesData = [...res.data];
        if (classesData.length == 0)
          this.$message.warning("Aucun type de qualification est trouvé");
        classesData = classesData.map((elem) => {
          return {
            ...elem,
            key: elem._id,
          };
        });
        this.data = classesData;
        this.cacheData = classesData.map((item) => ({ ...item }));
        this.tableLeading = false;
      })
      .catch((e) =>
        this.$message.error("Aucun type de qualification est trouvé")
      );
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("etablissement.nom"),
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
          sorter: (a, b) => a.name.length - b.name.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      tableLeading: true,
      visibleModal: false,
      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
    };
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addPosition(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          apiClient
            .put("/positions", { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.typeQualificationAjout"));
              res.data.key = res.data._id;
              this.data.push(res.data);
              this.handleCancel();
            })
            .catch((e) => this.$message.error(this.$t("error.impoAjoutType")))
            .finally(() => (this.loadingClasse = false));
        }
      });
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      const updates = { ...target };
      delete updates.key;
      apiClient
        .patch("/positions/" + key, { data: updates })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
          this.editingKey = "";
          this.$message.success(this.$t("success.typeQualificationMSA"));
        })
        .catch(() => {
          this.$message.error(this.$t("error.qualificationNonMSA"));
          this.cancel(key);
        });
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    supp(key) {
      apiClient
        .patch("/positions/" + key, { data: { status: "inactive" } })
        .then(() => {
          this.$message.success(this.$t("success.typeQualificationArchiv"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() =>
          this.$message.error(this.$t("error.qualificationNonSupp"))
        );
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
>
