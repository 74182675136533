var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticStyle:{"margin":"auto"}},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t("etablissement.cache")))]),(!_vm.stampURL)?_c('div',{staticClass:"upload-example",staticStyle:{"width":"600px"}},[_c('cropper',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCropper),expression:"showCropper"}],ref:"cropper",staticClass:"upload-example-cropper",attrs:{"stencil-size":{
          width: 500,
          height: 500,
        },"stencil-props":{
          handlers: {},
          movable: false,
          resizable: false,
          aspectRatio: 1,
        },"image-restriction":"stencil","src":_vm.image.src}}),_c('a-progress',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploadPercentage > 0),expression:"uploadPercentage > 0"}],attrs:{"stroke-color":{
          from: '#108ee9',
          to: '#87d068',
        },"percent":_vm.uploadPercentage,"status":"active"}}),_c('a-upload-dragger',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showCropper),expression:"!showCropper"}],attrs:{"multiple":false,"file-list":_vm.fileList,"customRequest":_vm.addTofileList,"before-upload":_vm.beforeUploadFiles}},[_c('div',[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":'file'}})],1),_c('div',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.$t("etablissement.clickOrDragToUpload"))+" ")])])])],1):_c('div',[_c('img',{attrs:{"src":_vm.stampURL,"width":"300"}})]),_c('div',{staticClass:"m-3",staticStyle:{"text-align":"center"}},[(_vm.stampURL)?_c('a-button',{attrs:{"type":"danger"}},[_c('a-popconfirm',{attrs:{"title":_vm.$t('requis.supp')},on:{"confirm":() => {
              _vm.supp();
            }}},[_c('a',[_vm._v(_vm._s(_vm.$t("action.supprimer")))])])],1):_c('div',[(_vm.showCropper)?_c('a-button',{staticClass:"ml-1 mr-1",on:{"click":() => {
              _vm.cancelUpload();
            }}},[_vm._v(" Annuler ")]):_vm._e(),_c('a-button',{attrs:{"type":"primary","loading":_vm.loadingAdd},on:{"click":_vm.crop}},[_vm._v(" Ajouter ")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }