<template>
  <div class="card">
    <a-button
      type="dashed"
      size="large"
      icon="book"
      @click="showModal"
      style="margin-bottom: 20px"
    >
      {{ $t("all.add") }}
    </a-button>
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :width="800"
      :height="1200"
      :footer="false"
    >
      <a-form :form="form" class="container" @submit="addScholarYear">
        <div class="row">
          <div class="col-md-6">
            <a-form-item :label="$t('all.startDate')">
              <a-date-picker
                style="width: 100%"
                v-decorator="[
                  'start',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('all.requiredStartDate'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-md-6">
            <a-form-item :label="$t('all.endDate')">
              <a-date-picker
                style="width: 100%"
                v-decorator="[
                  'end',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('all.requiredEndDate'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
          >
            {{ $t("all.add") }}</a-button
          >
          <a-button key="back" @click="handleCancel" :disabled="loadingClasse">
            {{ $t("all.cancel") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>
    <a-table
      :loading="tableLeading"
      :rowKey="'_id'"
      :columns="columns"
      :data-source="data"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
    >
      <template
        v-for="col in ['start', 'end']"
        :slot="col"
        slot-scope="text, record"
      >
        <div :key="col">
          <a-date-picker
            v-if="record.editable"
            :value="moment(text)"
            @change="(v) => handleChange(v, record._id, col)"
          />
          <template v-else>
            <div>{{ moment(text).format("DD-MM-YYYY") }}</div>
          </template>
        </div>
      </template>
      <span slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
          <span v-if="record.editable">
            <a-button
              size="small"
              type="primary"
              @click="() => save(record._id)"
            >
              {{ $t("all.save") }}
            </a-button>
            <a-button size="small" type="danger">
              <a-popconfirm
                :title="$t('all.sureToCancelAlert')"
                @confirm="() => cancel(record._id)"
              >
                <a>{{ $t("all.cancel") }}</a>
              </a-popconfirm>
            </a-button>
          </span>
          <span v-else>
            <a-button type="primary" :disabled="editingKey !== ''">
              <a-popconfirm
                :title="$t('etablissement.editAlert')"
                @confirm="() => edit(record._id)"
                ><a><a-icon type="edit" />{{ $t("all.edit") }}</a>
              </a-popconfirm>
            </a-button>
          </span>
        </div>
      </span>
    </a-table>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
var activitiessData = [];
export default {
  name: "activities",
  computed: mapState(["settings"]),
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "classForm" });
  },
  created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Année Scolaire",
    });

    apiClient
      .post("/schoolaryears/filter", {
        query: {},
      })
      .then((res) => {
        activitiessData = [...res.data].map((e) => ({ ...e, key: e._id }));
        this.data = activitiessData;
        this.cacheData = activitiessData.map((item) => ({ ...item }));
        this.tableLeading = false;
      })
      .catch((e) => this.$message.error(this.$t("error.aucAnnee")));
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("all.startDate"),
          dataIndex: "start",
          key: "start",
          scopedSlots: { customRender: "start" },
        },
        {
          title: this.$t("all.endDate"),
          dataIndex: "end",
          key: "end",
          scopedSlots: { customRender: "end" },
        },
        {
          title: this.$t("all.edit"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
      ],
      services: [],
      tableLeading: true,
      visibleModal: false,
      form: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingClasse: false,
      editingKey: "",
    };
  },
  methods: {
    moment,
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addScholarYear(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          apiClient
            .put("/schoolaryears", { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.anneeAjout"));
              this.data.push(res.data);
              let scs = this.settings.schoolarYears;
              scs.push({
                ...res.data,
                name:
                  new Date(res.data["start"]).getFullYear() +
                  "-" +
                  new Date(res.data["end"]).getFullYear(),
              });
              this.$store.commit("settings/CHANGE_SETTING", {
                setting: "schoolarYears",
                value: scs,
              });
              this.handleCancel();
            })
            .catch((e) => this.$message.error(this.$t("error.impoAjoutAnnee")))
            .finally(() => (this.loadingClasse = false));
        }
      });
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      const updates = target;
      apiClient
        .patch("/schoolaryears/" + key, { data: updates })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
          this.editingKey = "";
          this.$message.success(this.$t("success.anneeMSA"));
          let scs = [...this.data].map((sc) => ({
            ...sc,
            name:
              new Date(sc["start"]).getFullYear() +
              "-" +
              new Date(sc["end"]).getFullYear(),
          }));
          this.$store.commit("settings/CHANGE_SETTING", {
            setting: "schoolarYears",
            value: scs,
          });
        })
        .catch(() => {
          this.$message.error(this.$t("error.anneeNonSupp"));
          this.cancel(key);
        });
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    supp(key) {
      apiClient
        .patch("/schoolaryears/" + key, { data: { status: "inactive" } })
        .then(() => {
          this.$message.success(this.$t("success.anneeSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() => this.$message.error(this.$t("error.anneeNonSupp")));
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
