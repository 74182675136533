<template>
  <div class="card">
    <a-button
      type="dashed"
      size="large"
      icon="book"
      @click="showModal"
      style="margin-bottom: 20px"
    >
      {{ $t("action.ajouter") }}
    </a-button>
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :width="500"
      :height="1200"
      :footer="false"
    >
      <a-form :form="form" @submit="addService">
        <a-form-item :label="$t('etablissement.nom')">
          <a-input
            :placeholder="$t('etablissement.nom')"
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: $t('requis.nom'),
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item :label="$t('etablissement.fournisseurNonRequis')">
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            :placeholder="$t('etablissement.fournisseur')"
            v-decorator="[
              'provider',
              {
                rules: [
                  {
                    required: false,
                  },
                ],
              },
            ]"
          >
            <a-select-option v-for="provider in providers" :key="provider._id">
              {{ provider.fullName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            htmlType="submit"
            :loading="loadingClasse"
            :disabled="loadingClasse"
            class="mr-3"
          >
            {{ $t("action.ajouter") }}
          </a-button>
          <a-button key="back" @click="handleCancel" :disabled="loadingClasse">
            {{ $t("action.annuler") }}
          </a-button>
        </div>
      </a-form>
    </a-modal>
    <a-table
      :loading="tableLeading"
      :rowKey="'_id'"
      :columns="columns"
      :data-source="data"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
    >
      <template slot="name" slot-scope="text, record">
        <a-input
          v-if="record.editable"
          type="text"
          :disabled="false"
          style="margin: -5px 0"
          :default-value="text"
          @change="(e) => handleChange(e.target.value, record._id, 'name')"
        />
        <div v-else>
          <template>
            {{ text }}
          </template>
        </div>
      </template>
      <a-select
        slot="provider"
        slot-scope="text, record"
        show-search
        v-if="record.editable"
        :autoFocus="true"
        style="width: 100%"
        :placeholder="$t('recette.fournisseur')"
        option-filter-prop="children"
        :filter-option="filterOption"
        @change="(e) => handleChange(e, record._id, 'provider')"
        :default-value="record.provider ? record.provider : ''"
      >
        <a-select-option
          v-for="provider in providers"
          :value="provider._id"
          :key="provider._id"
        >
          {{ provider.fullName }}
        </a-select-option>
      </a-select>
      <template v-else> {{ getProvider(record) }} </template>
      <span slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
          <span v-if="record.editable">
            <a-button
              size="small"
              type="primary"
              @click="() => save(record._id)"
            >
              {{ $t("action.enregistrer") }}
            </a-button>
            <a-button size="small" type="danger">
              <a-popconfirm
                :title="$t('all.sureToCancelAlert')"
                @confirm="() => cancel(record._id)"
              >
                <a> {{ $t("action.annuler") }} </a>
              </a-popconfirm>
            </a-button>
          </span>
          <span v-else>
            <a-button
              type="primary"
              :disabled="editingKey !== ''"
              @click="() => edit(record._id)"
            >
              <a-icon type="edit" />{{ $t("action.modifier") }}
            </a-button>
          </span>
        </div>
      </span>
      <span slot="operationSupprimer" slot-scope="text, record">
        <div class="editable-row-operations">
          <span>
            <a-button :disabled="editingKey !== ''" type="danger">
              <a-popconfirm
                :title="$t('requis.supp')"
                @confirm="() => supp(record._id)"
              >
                <a>{{ $t("action.supprimer") }}</a>
              </a-popconfirm>
            </a-button>
          </span>
        </div>
      </span>
    </a-table>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
var servicesData = [];
export default {
  name: "services",
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "servicesForm" });
  },
  created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Services",
    });
  },
  beforeCreate() {
    apiClient
      .post("/services/filter", {
        query: { status: "active" },
      })
      .then((res) => {
        servicesData = [...res.data];
        servicesData = servicesData.map((elem) => {
          return {
            ...elem,
            key: elem._id,
          };
        });
        this.data = servicesData;
        this.cacheData = servicesData.map((item) => ({ ...item }));
        this.tableLeading = false;
      })
      .catch((e) => this.$message.error(this.$t("error.aucService")));
    apiClient
      .post("/providers/filter", { query: {} })
      .then((res) => {
        this.providers = res.data;
      })
      .catch((e) => this.$message.error(this.$t("error.aucFournisseur")));
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("etablissement.nom"),
          dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.$t("etablissement.fournisseurs"),
          dataIndex: "provider",
          key: "provider",
          scopedSlots: { customRender: "provider" },
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      tableLeading: true,
      visibleModal: false,
      form: this.$form.createForm(this),
      data: null,
      providers: [],
      cacheData: null,
      loadingClasse: false,
      activeService: "",
      editingKey: "",
    };
  },
  methods: {
    handleChangeProvider(id) {
      this.activeService = id;
    },
    getProvider(record) {
      if (record.provider) {
        const provider = this.providers.filter(
          (el) => el._id == record.provider
        )[0];
        if (provider) return provider.fullName;
      }
      return "-";
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addService(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingClasse = true;

          apiClient
            .put("/services", { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.serviceAjout"));
              this.data.push({
                ...res.data,
                key: res.data._id,
              });
              this.handleCancel();
            })
            .catch((e) =>
              this.$message.error(this.$t("error.impoAjoutServices"))
            )
            .finally(() => (this.loadingClasse = false));
        }
      });
    },
    showModal() {
      this.visibleModal = true;
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
      if (column === "provider") this.activeService = key;
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      let newData = [...this.data];
      let newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      /* if (this.activeService) {
        newData = newData.map((el) => ({
          ...el,
          provider: el._id == target._id ? this.activeService : el.provider,
        }));
        newCacheData = newCacheData.map((el) => ({
          ...el,
          provider: el._id == target._id ? this.activeService : el.provider,
        }));
      }*/
      const updates = target;
      console.log(updates);

      apiClient
        .patch("/services/" + key, { data: updates })
        .then(() => {
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
          this.editingKey = "";
          this.$message.success(this.$t("success.serviceMSA"));
        })
        .catch(() => {
          this.$message.error(this.$t("error.serviceNonMSA"));
          this.cancel(key);
        });
      this.activeService = null;
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    supp(key) {
      apiClient
        .patch("/services/" + key, { data: { status: "inactive" } })
        .then(() => {
          this.$message.success(this.$t("success.serviceSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch(() => this.$message.error(this.$t("error.serviceNonSupp")));
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
>
